<h2 class="heading">
  <mat-icon *ngIf="needsAttentionModel?.isApplicable" class="heading__icon mat-orange"
  [matTooltip]="needsAttentionMessage" [matTooltipClass]="'status-tooltip-warning'">warning</mat-icon>
  {{entity?.dba || entity?.name}} Other financing
</h2>
<div class="card"
     *ngFor="let loan of loans">
  <div class="icon">
    <mat-icon class="icon__content">data_saver_off</mat-icon>
  </div>
  <ifm-other-financing-widget-info class="card__info"
                                   [loan]="loan"
                                   (click)="onDetails(loan)">
  </ifm-other-financing-widget-info>

  <ifm-other-financing-widget-balance class="card__balance"
                                      [ngClass]="{'card__balance__hidden': loan.status === loanStatus.Pending ||
                                        loan.status === loanStatus.PendingRenewal ||
                                        loan.status === loanStatus.PaidOff ||
                                        loan.status === loanStatus.Closed ||
                                        loan.status === loanStatus.PendingClosure ||
                                        kbaVerified !== kbaStatusEnum.Verified }"
                                      [loan]="loan"
                                      (draw)="onDraw(loan)"
                                      (pay)="onPay(loan)">
  </ifm-other-financing-widget-balance>

  <ifm-other-financing-widget-menu class="card__menu"
                                   [loan]="loan"
                                   (details)="onDetails(loan)"
                                   (draw)="onDraw(loan)"
                                   (pay)="onPay(loan)">
  </ifm-other-financing-widget-menu>
</div>
<ng-container *ngIf="loans?.length > 2">
  <div class="actions">
    <button mat-button
            class="secondary-text"
            (click)="onClickSeeAllLoans()">SEE ALL LOANS</button>
  </div>
</ng-container>
