<ng-container *ngIf="data?.entitiesData?.length === 1; else multipleEntitySelect">
  <ng-container *ngTemplateOutlet="singleEntitySelect"></ng-container>
</ng-container>

<ng-template #singleEntitySelect
             let-entityData="entityData">
  <mat-form-field appearance="outline"
                  class="select-wrapper">
    <mat-label>{{data?.config?.inputLabel}}</mat-label>
    <mat-select [formControl]="selectForm"
                [placeholder]="data?.config?.inputLabel">
      <mat-select-trigger>
        {{selectForm.value?.label}}
      </mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search [formControl]="filterForm"
                               [placeholderLabel]="data?.config?.singleEntitySearchLabel"
                               noEntriesFoundLabel="No matching results found">
        </ngx-mat-select-search>
      </mat-option>

      <ng-container *ngIf="filteredEntitiesData$ | async as entitiesData">
        <mat-option *ngFor="let case of entitiesData?.[0]?.cases"
                    [value]="{ entityId: entitiesData?.[0]?.entityId, caseId: case.caseId, loanId: case.loanId, label: case.name, productCode: case.productCode }">
          <mat-icon *ngIf="case.loanStatus === LoanStatus.Frozen" class="mat-orange" [matTooltipClass]="'mat-tooltip status-tooltip-warning'"
          [matTooltip]="entitiesData?.[0]?.needsAttentionModel | needsAttentionModelToMessage : case.productCode">warning</mat-icon>
          {{case.name}}
        </mat-option>
      </ng-container>

    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #multipleEntitySelect
             let-entitiesData="entitiesData">
  <mat-form-field appearance="outline"
                  class="select-wrapper">
    <mat-label>Case or loan</mat-label>
    <mat-select [formControl]="selectForm"
                placeholder="Case or loan">
      <mat-select-trigger>
        {{selectForm.value?.label}}
      </mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search [clearSearchInput]="false"
                               [searching]="searching"
                               [formControl]="filterForm"
                               [placeholderLabel]="data?.config?.multipleEntitySearchLabel"
                               noEntriesFoundLabel="No matching results found">
        </ngx-mat-select-search>
      </mat-option>
      <ng-container *ngIf="filteredEntitiesData$ | async as entitiesData">
        <mat-optgroup *ngFor="let entityData of entitiesData"
                      [label]="entityData.entityName">
          <mat-option *ngFor="let case of entityData?.cases"
                      [value]="{ entityId: entityData.entityId, caseId: case.caseId, loanId: case.loanId, label: entityData.entityName + ' - ' + case.name, productCode: case.productCode }">
                      <mat-icon *ngIf="case.loanStatus === LoanStatus.Frozen" class="mat-orange" 
                      [matTooltipClass]="'mat-tooltip status-tooltip-warning'" [matTooltip]="entityData?.needsAttentionModel | needsAttentionModelToMessage : case.productCode">warning</mat-icon>
                      {{case.name}}
          </mat-option>
        </mat-optgroup>
      </ng-container>
    </mat-select>
  </mat-form-field>
</ng-template>
