import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { KBAVerificationStatus, LoanData, LoanStatus, NeedsAttentionHelper, NeedsAttentionModel, ProductCode, ProductCodeLabel } from "common";
import { ActionsService } from "../../../../core/actions/actions.service";
import { CustomerFacade } from "../../../customer/domain/+state/customer.facade";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { take } from "rxjs/operators";
import { Entity } from "../../../entity/domain/models/entity.model";

@UntilDestroy()
@Component({
  selector: "ifm-others-financing-widget",
  templateUrl: "./others-financing-widget.component.html",
  styleUrls: ["./others-financing-widget.component.scss"],
})
export class OthersFinancingWidgetComponent implements OnChanges {
  @Input() entity: Entity;
  @Input() loans: LoanData[];
  @Input() needsAttentionModel: NeedsAttentionModel;
  
  loanStatus = LoanStatus;
  kbaStatus: KBAVerificationStatus;
  kbaStatusEnum = KBAVerificationStatus;
  needsAttentionMessage: string;

  constructor(private actions: ActionsService,
    private customerFacade: CustomerFacade) {}

  ngOnChanges(_: SimpleChanges): void {
    this.initNeedsAttentionModel();
  }

  onClickSeeAllLoans() {
    this.actions.seeAllLoans({ entityId: this.entity.id });
  }

  //TODO: Refactor by moving getCustomerCheckoutState$ to getDashboardData$, pass as input like loans and entity
  onDetails(loan: LoanData): void {
    this.customerFacade
      .getCustomerCheckoutState$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((kbaState) => {
        this.dispatchOtherFinancingDetails(loan, kbaState?.verifyKba);
        this.kbaStatus = kbaState?.status;
      });
  }

  private initNeedsAttentionModel() {
    this.needsAttentionMessage = NeedsAttentionHelper.getNeedsAttentionMessageWithProductCode(this.needsAttentionModel?.messageInput, ProductCode.LOC);
  }

  private dispatchOtherFinancingDetails(loan: LoanData, verifyKba: boolean): void {
    this.actions.otherFinancingDetails({
      entityId: this.entity.id,
      loanId: loan.id,
      loanStatus: loan.status,
      verifyKba: verifyKba
    });
  }

  onDraw(loan: LoanData) {
    this.actions.draw({ entityId: this.entity.id, loanId: loan.id, needsAttentionModel: {
      ...this.needsAttentionModel, messageInput: { 
        ...this.needsAttentionModel.messageInput, productName: ProductCodeLabel.get(loan.productCode) }}});
  }

  onPay(loan: LoanData) {
    this.actions.pay({ entityId: this.entity.id, loanId: loan.id });
  }
}
