import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CustomerLoanStatusLabel, LoanStatus } from 'common';
import { LitigationStatusLabel } from './litigation-status-icon-chip.model';

@Component({
  selector: 'ifm-litigation-status-icon-chip',
  templateUrl: './litigation-status-icon-chip.component.html',
  styleUrls: ['./litigation-status-icon-chip.component.scss']
})
export class LitigationStatusIconChipComponent implements OnChanges {
  
  @Input()
  status = 'open';
  
  @Input()
  tooltip: string;

  statusLabels: LitigationStatusLabel
  
  ngOnChanges(_: SimpleChanges): void {
    this.initStatusLabels();
  }

  private initStatusLabels() {
    this.statusLabels = {
      'pending': { color: 'white-background __gray-icon', icon: 'help', label: 'Pending' },
      'approved': { color: 'white-background __green-icon', icon: 'check_circle', label: 'Approved' },
      'frozen': { color: 'white-background __orange-icon', icon: 'warning', label: CustomerLoanStatusLabel.get(LoanStatus.Frozen), tooltipMsg: this.tooltip },
      'declined': { color: 'white-background __purple-icon', icon: 'warning', label: 'Declined' },
      'payment-required': { color: 'white-background __orange-icon', icon: 'payments', label: 'Payment Due'}
    };
  }
}
