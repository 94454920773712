<ifc-app-scroll-container>
  <table mat-table
         aria-label="Recent activities table"
         [dataSource]="data?.cases"
         *ngIf="data?.cases?.length">
    <ng-container matColumnDef="icons">
      <td mat-cell
          *matCellDef="let row"
          class="table-cell table-cell--icons">
        <span *ngIf="row.caseData.hasLcfLoan"
              matTooltip="Case Financing"
              class="material-symbols-outlined icon">monetization_on</span>
        <span *ngIf="row.caseData.hasLcpPolicy"
              matTooltip="Insurance"
              class="material-symbols-outlined icon">shield</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <td mat-cell
          *matCellDef="let row"
          class="table-cell table-cell--name"
          [ngClass]="{'clickable-cell': row.metaData.canGoToDetails}"
          (click)="onClickRow(row)">
        {{row.caseData.caseName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <td mat-cell
          *matCellDef="let row"
          class="table-cell table-cell--status"
          [ngClass]="{'clickable-cell': row.metaData.canGoToDetails}"
          (click)="onClickRow(row)">
        <ifm-litigation-status-icon-chip *ngIf="row.metaData.hasStatusIcon"
                                         [status]="row.caseData.status"
                                         [tooltip]="needsAttentionModel | needsAttentionModelToMessage : ProductCode.Law">
        </ifm-litigation-status-icon-chip>
        <span class="table-cell table-cell--status"
              *ngIf="!row.metaData.hasStatusIcon">
          {{row.caseData.status | enumValueLabel:LitigationStatus:LitigationStatusLabel}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <td mat-cell
          *matCellDef="let row"
          class="table-cell table-cell--action">
        <ng-container *ngIf="row.metaData.canFundCase">
          <button mat-button
                  color="primary"
                  (click)="onClickFundCase(row)">FUND CASE</button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="endIcon">
      <td mat-cell
          *matCellDef="let row"
          class="table-cell table-cell--endIcon"
          [ngClass]="{'clickable-cell': row.metaData.canGoToDetails}"
          (click)="onClickRow(row)">
        <mat-icon *ngIf="row.metaData.canGoToDetails">navigate_next</mat-icon>
      </td>
    </ng-container>

    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"
        class="table-row"
        [matTooltip]="row.metaData.tooltipMessage"
        matTooltipPosition="right">
    </tr>
  </table>

</ifc-app-scroll-container>
