import { Injectable, Type } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  DashboardData,
  DashboardEntityData,
} from "../../models/dashboard.model";
import { Observable } from "rxjs";
import {
  DashboardActionEntityData,
  DashboardActionCaseData,
  DashboardActionConfig,
} from "./models/dashboard-actions.model";
import { LoanHelper, LoanStatus, ProductCode } from "common";
import { AgreementStatus } from "../../../entity/domain/models/entity.model";

@Injectable()
export class DashboardActionsFacade {
  constructor(private dialog: MatDialog) {}

  showActionDialog(
    componentType: Type<any>,
    entitiesData: DashboardActionEntityData[],
    config: DashboardActionConfig
  ): Observable<any> {
    return this.dialog
      .open(componentType, {
        width: "510px",
        data: { entitiesData, config },
        closeOnNavigation: true,
      })
      .afterClosed();
  }
  
  adaptDrawFundsEntitiesData(data: DashboardData): DashboardActionEntityData[] {
    return data?.entitiesData?.reduce((acc, item) => {
      const eligibles = [...this.adaptDrawFundsCaseData(item), ...this.adaptDrawFundsOtherFinancingData(item)];
      if (eligibles?.length > 0) {
        const newAcc = {
          entityId: item.entity?.id,
          entityName: item.entity?.dba || item.entity?.name,
          cases: eligibles,
          needsAttentionModel: item.needsAttentionModel
        };
        acc.push(newAcc);
      }
      return acc;
    }, []);
  }

  private adaptDrawFundsCaseData(
    entityData: DashboardEntityData
  ): DashboardActionCaseData[] {
    if(entityData?.entity?.msaAgreementInfo?.status !== AgreementStatus.Signed) {
      return [];
    }
    return entityData?.cases?.reduce((acc, item) => {
      if (item.hasLcfLoan && (item.lcfLoan.drawAllowed || item.lcfLoan.status === LoanStatus.Frozen) && !item.litigationResolutionStatus) {
        acc.push({
          caseId: item.litigationId,
          loanId: item.lcfLoan?.loanId,
          name: item.caseName,
          loanStatus: item.lcfLoan?.status,
          productCode: ProductCode.Law
        });
      }
      return acc;
    }, []);
  }

  private adaptDrawFundsOtherFinancingData(
    entityData: DashboardEntityData
  ): DashboardActionCaseData[] {
    return entityData?.otherFinancingLoans?.reduce(
      (acc, item) => {
        if (LoanHelper.hasDrawAccess(item)) {
          acc.push({
            loanId: item.id,
            name: item.loanNumber,
            loanStatus: item.status,
            productCode: item.productCode
          });
        }
        return acc;
      },
      []);
  }

  adaptResolvedEntitiesData(data: DashboardData): DashboardActionEntityData[] {
    return data?.entitiesData?.reduce((acc, item) => {
      const eligibleCases = this.adaptResolvedCaseData(item);
      if (eligibleCases?.length > 0) {
        acc.push({
          entityId: item.entity?.id,
          entityName: item.entity?.dba || item.entity?.name,
          cases: eligibleCases,
        });
      }
      return acc;
    }, []);
  }

  private adaptResolvedCaseData(
    entityData: DashboardEntityData
  ): DashboardActionCaseData[] {
    if(entityData?.entity?.msaAgreementInfo?.status !== AgreementStatus.Signed) {
      return [];
    }
    return entityData?.cases?.reduce((acc, item) => {
      if (item.hasLcfLoan && !item.litigationResolutionStatus) {
        acc.push({
          caseId: item.litigationId,
          loanId: item.lcfLoan?.loanId,
          name: item.caseName,
        });
      }
      return acc;
    }, []);
  }
}
