import { Injectable } from "@angular/core";
import { EntityFacade } from "../../entity/domain/+state/entity.facade";
import { LitigationFacade } from "../../litigation/domain/+state/litigation.facade";
import { concatMap, map, mergeMap, toArray } from "rxjs/operators";
import { Observable, combineLatest, forkJoin, from } from "rxjs";
import { DashboardData, DashboardEntityData } from "../models/dashboard.model";
import { LoanFacade } from "../../loan/domain/+state/loan.facade";
import { MinimalLeadService } from "../../application-form/service/minimal-lead.service";
import { Entity } from "../../entity/domain/models/entity.model";
import { BrandingService, CompanyBranding, CompanyBrandingHelper, EntityHelper, NeedsAttentionModel } from "common";

@Injectable()
export class DashboardDataService {
  constructor(
    private entityFacade: EntityFacade,
    private litigationFacade: LitigationFacade,
    private loanFacade: LoanFacade,
    private minimalLeadService: MinimalLeadService,
    private brandingService: BrandingService
  ) {}

  getDashboardData$(): Observable<DashboardData> {
    return combineLatest([
      this.getEntitiesData(),
      this.minimalLeadService.getNotSubmitted(),
    ]).pipe(
      map(([entitiesData, notSubmittedLead]) => ({
        entitiesData,
        existsNotSubmittedApplication: !!notSubmittedLead,
      }))
    );
  }

  private getEntitiesData(): Observable<DashboardEntityData[]> {
    return combineLatest([this.entityFacade.getEntities$(), this.brandingService.getBranding$()]).pipe(
      mergeMap((combinedResult: Array<Entity[] | CompanyBranding>) => {
        const entities = combinedResult[0] as Entity[];
        const branding = combinedResult[1] as CompanyBranding;
        return from(entities).pipe(
          concatMap((entity) => this.getDataPerEntity(entity, branding)),
          toArray()
        );
      })
    );
  }

  private getDataPerEntity(
    entity: Entity,
    branding: CompanyBranding
  ): Observable<DashboardEntityData> {
    return forkJoin([
      this.litigationFacade.getByEntity(entity.id),
      this.loanFacade.getOtherFinancingLoansByEntity(entity.id),
    ]).pipe(
      map(([cases, otherFinancingLoans]) => {
        const advisorPhone = CompanyBrandingHelper.getAdvisorPhone(branding, entity?.advisorPhone);
        return {
        cases,
        otherFinancingLoans,
        entity,
        needsAttentionModel: this.getNeedsAttentionModel({...entity, advisorPhone: advisorPhone})
        };
      })
    );
  }

  private getNeedsAttentionModel(entity: Entity): NeedsAttentionModel {
    return {
      isApplicable: entity?.isDrawSuspended,
      messageInput: EntityHelper.getNeedsAttentionMessage(entity)
    };
  }
}
