<div class="product">
  <span class="product__name">{{productName}}</span>
  <span class="product__details">{{loan?.status | enumValueLabel:LoanStatus:CustomerLoanStatusLabel}} - {{balance | currency:'$'}} balance</span>
  <span class="product__details"
        *ngIf="loan?.loanInfo?.paymentDueDate; else noPaymentDue">
    {{loan?.loanInfo?.upcomingPayment | currency:'$'}} due on
    {{loan?.loanInfo?.paymentDueDate | date: 'shortDate'}}
  </span>
  <ng-template #noPaymentDue><span class="product__details">No payment due</span></ng-template>
</div>
