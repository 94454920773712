import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import {
  FundCaseActionData,
  CaseDetailsActionData,
  DashboardActionBaseData,
  OtherFinancingDetailsActionData,
  DrawFinancingActionData,
  ResolvedActionData,
  MsaApplicationActionData,
  PayFinancingActionData
} from "./actions.model";

@Injectable()
export class ActionsService {
  private readonly fundCaseSubject: Subject<FundCaseActionData> = new Subject();
  fundCase$: Observable<FundCaseActionData> =
    this.fundCaseSubject.asObservable();
  fundCase = (data: FundCaseActionData) => this.fundCaseSubject.next(data);

  private readonly fundCaseMsaApplicationSubject: Subject<FundCaseActionData> = new Subject();
  fundCaseMsaApplication$: Observable<FundCaseActionData> =
    this.fundCaseMsaApplicationSubject.asObservable();
  fundCaseMsaApplication = (data: FundCaseActionData) => this.fundCaseMsaApplicationSubject.next(data);

  private readonly msaApplicationSubject: Subject<MsaApplicationActionData> = new Subject();
  msaApplication$: Observable<MsaApplicationActionData> =
    this.msaApplicationSubject.asObservable();
  msaApplication = (data: MsaApplicationActionData) => this.msaApplicationSubject.next(data);

  private readonly caseDetailsSubject: Subject<CaseDetailsActionData> = new Subject();
  caseDetails$: Observable<CaseDetailsActionData> =
    this.caseDetailsSubject.asObservable();
  caseDetails = (data: CaseDetailsActionData) =>
    this.caseDetailsSubject.next(data);

  private readonly seeAllLoansSubject: Subject<DashboardActionBaseData> = new Subject();
  seeAllLoans$: Observable<DashboardActionBaseData> =
    this.seeAllLoansSubject.asObservable();
  seeAllLoans = (data: DashboardActionBaseData) =>
    this.seeAllLoansSubject.next(data);

  private readonly otherFinancingDetailsSubject: Subject<OtherFinancingDetailsActionData> =
    new Subject();
  otherFinancingDetails$: Observable<OtherFinancingDetailsActionData> =
    this.otherFinancingDetailsSubject.asObservable();
  otherFinancingDetails = (data: OtherFinancingDetailsActionData) =>
    this.otherFinancingDetailsSubject.next(data);

  private readonly drawSubject: Subject<DrawFinancingActionData> = new Subject();
  draw$: Observable<DrawFinancingActionData> = this.drawSubject.asObservable();
  draw = (data: DrawFinancingActionData) => this.drawSubject.next(data);

  private readonly paySubject: Subject<PayFinancingActionData> = new Subject();
  pay$: Observable<PayFinancingActionData> = this.paySubject.asObservable();
  pay = (data: PayFinancingActionData) => this.paySubject.next(data);

  private readonly seeAllDocumentsSubject: Subject<DashboardActionBaseData> =
    new Subject();
  seeAllDocuments$: Observable<DashboardActionBaseData> =
    this.seeAllDocumentsSubject.asObservable();
  seeAllDocuments = (data: DashboardActionBaseData) =>
    this.seeAllDocumentsSubject.next(data);

  private readonly resolvedSubject: Subject<ResolvedActionData> = new Subject();
  resolved$: Observable<ResolvedActionData> =
    this.resolvedSubject.asObservable();
  resolved = (data: ResolvedActionData) => this.resolvedSubject.next(data);
}
